<template>
    <!-- eslint-disable max-len -->
    <footer class="layout-TheBottomMenu bg-day-surface dark:bg-night-surface pt-4 pb-8 px-2 grid grid-cols-5 col-gap-2 fixed bottom-0 w-full z-20 lg:max-w-lg mx-auto" :class="{ 'opacity-50 cursor-not-allowed pointer-events-none': disabled }">
        <RouterLink :to="{ name: 'HomeScreen' }" class="flex flex-col items-center" :class="{ 'opacity-50 pointer-events-none': !isConnected }">
            <AppIcon icon-name="junea" :width="24" :height="24" class="text-day-on-surface-ultra-emphasis dark:text-white" />
        </RouterLink>

        <button v-if="machine && machine.type === 'fridge'" @click="$router.push({ name: 'OrderFridge' })" class="flex flex-col items-center" :class="{ 'opacity-50': !isConnected || !machine, 'router-link-active': $route.name === 'OrderFridge' }">
            <AppIcon icon-name="grid" :width="24" :height="24" class="text-day-on-surface-ultra-emphasis dark:text-white" />
        </button>

        <button v-else @click="machine ? $router.push({ name: 'OrderJunea' }) : toggleNoMachineSelectedDialog()" class="flex flex-col items-center" :class="{ 'opacity-50': !isConnected || !machine, 'router-link-active': $route.name === 'OrderJunea' }">
            <AppIcon icon-name="grid" :width="24" :height="24" class="text-day-on-surface-ultra-emphasis dark:text-white" />
        </button>

        <button @click="machine ? $router.push({ name: 'OrderCart' }) : toggleNoMachineSelectedDialog()"  class="flex flex-col items-center relative" :class="{ 'opacity-50': !isConnected || !machine, 'router-link-active': $route.name === 'OrderCart', 'opacity-50 pointer-events-none': tapAndGoEnabled }">
            <AppIcon icon-name="shopping-cart" :width="24" :height="24" class="text-day-on-surface-ultra-emphasis dark:text-white" />
            <span v-if="totalCartSize > 0" class="layout-TheBottomMenu_CartAmount absolute rounded-full bg-primary-brand text-white font-medium w-4 h-4 flex justify-center items-center">{{ totalCartSize }}</span>
        </button>

        <RouterLink :to="{ name: 'Account' }" class="flex flex-col items-center">
            <AppIcon icon-name="user" :width="24" :height="24" class="text-day-on-surface-ultra-emphasis dark:text-white" />
        </RouterLink>

        <RouterLink :to="{ name: 'More' }" class="flex flex-col items-center">
            <AppIcon icon-name="ellipsis-horizontal" :width="24" :height="24" class="text-day-on-surface-ultra-emphasis dark:text-white" />
        </RouterLink>

        <portal to="dialogs">
            <NoMachineSelectedDialog
                v-if="showNoMachineSelectedDialog"
                @close="toggleNoMachineSelectedDialog" />
        </portal>
    </footer>
</template>

<script>
import NoMachineSelectedDialog from '@/components/Dialogs/NoMachineSelected.vue';
import AppIcon from '@/components/Interface/AppIcon.vue';
import useConnection from '@/composables/useConnection';
import useMachine from '@/composables/useMachine';
import useOrder from '@/composables/useOrder';
import useTapAndgo from '@/composables/useTapAndGo';
import { ref } from '@vue/composition-api';

export default {
    name: 'LayoutTheBottomMenu',

    components: {
        NoMachineSelectedDialog,
        AppIcon,
    },

    props: {
        disabled: Boolean,
    },

    setup() {
        const { isConnected } = useConnection();
        const { machine } = useMachine();
        const { totalCartSize } = useOrder();
        const { enabled: tapAndGoEnabled } = useTapAndgo();

        const showNoMachineSelectedDialog = ref(false);

        const toggleNoMachineSelectedDialog = () => {
            showNoMachineSelectedDialog.value = !showNoMachineSelectedDialog.value;
        };

        return {
            isConnected,
            machine,
            showNoMachineSelectedDialog,
            toggleNoMachineSelectedDialog,
            totalCartSize,
            tapAndGoEnabled,
        };
    },
};
</script>

<style lang="scss">
.layout-TheBottomMenu {

    .router-link-active {

        i svg, span {
            color: theme('colors.primary.brand');
        }
    }

    &_CartAmount {
        font-size: 10px;
        right: 23%;
        top: -8px;
        color: white !important;
    }
}
</style>
