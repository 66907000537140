<template>
    <!-- eslint-disable max-len -->
    <header class="layout-TheDefaultHeader p-8 flex justify-between items-center flex-shrink-0 space-x-4" :class="{ 'text-white': forceLight }">
        <div v-if="help || back" class="flex-shrink-0 flex items-center">
            <button v-if="help" type="button" @click="startOnboarding" aria-label="App Onboarding">
                <AppIcon icon-name="help" :width="24" :height="24" />
            </button>

            <button v-if="back" type="button" @click="$router.back()" aria-label="Terug">
                <AppIcon icon-name="arrow-left" :width="24" :height="24" />
            </button>
        </div>

        <div class="flex-shrink-0 flex-grow flex justify-center w-full">
            <RouterLink v-if="machine && loggedIn" :to="{ name: machine.type === 'fridge' ? 'OrderFridge' :  'OrderJunea' }">
                <div class="layout-TheDefaultHeader_Machine flex items-center">
                    <img :src="require(`@/assets/images/machine-types/${machine.type}.png`)" :alt="machine.name" class="h-12" />

                    <div class="layout-TheDefaultHeader_MachineInfo ml-3">
                        <h3 class="font-medium leading-none">{{ machine.name }}</h3>

                        <div class="flex items-center text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis">
                            <AppIcon icon-name="marker" :width="12" :height="12" />
                            <span class="ml-1 font-normal">{{ machine.location_name }}</span>
                        </div>
                    </div>
                </div>
            </RouterLink>

            <RouterLink v-else :to="{ name: 'HomeScreen' }">
                <SVGLogo height="44" />
            </RouterLink>
        </div>

        <div v-if="close" class="flex-shrink-0 flex justify-end items-center">
            <RouterLink v-if="close" :to="{ name: 'HomeScreen' }">
                <AppIcon icon-name="exit" :width="24" :height="24" />
            </RouterLink>
        </div>
    </header>
</template>

<script>
import SVGLogo from '@/assets/svg/logo.svg?inline';
import AppIcon from '@/components/Interface/AppIcon.vue';
import router from '@/router';
import useMachine from '@/composables/useMachine';
import useAuth from '@/composables/useAuth';

export default {
    name: 'LayoutTheDefaultHeader',

    props: {
        help: Boolean,
        back: Boolean,
        close: Boolean,
        forceLight: Boolean,
    },

    components: {
        SVGLogo,
        AppIcon,
    },

    setup() {
        const { machine } = useMachine();
        const { loggedIn } = useAuth();

        /**
         * Restart the onboarding proces by clearing
         * onboarding completed item in local storage
         * @returns {void}
         */
        const startOnboarding = () => {
            localStorage.removeItem('onboarding-completed');
            router.push({ name: 'Onboarding' });
        };

        return {
            startOnboarding,
            machine,
            loggedIn,
        };
    },
};
</script>

<style lang="scss" scoped>
.layout-TheDefaultHeader {}
</style>
