<template>
    <!-- eslint-disable max-len -->
    <BaseDialog @close="$emit('close')" class="NoMachineSelectedDialog">
        <div class="NoMachineSelectedDialog_Message text-center">
            <h1 v-t="'no-machine-selected-dialog.title'"></h1>
            <p v-t="'no-machine-selected-dialog.description'" class="mt-1 text-day-on-surface-medium-emphasis dark:text-night-on-surface-medium-emphasis"></p>
        </div>

        <div class="NoMachineSelectedDialog_Actions mt-6 grid grid-cols-2 gap-2">
            <AppButton @click="selectMachine" size="small" width="full">{{ $t('no-machine-selected-dialog.button-select') }}</AppButton>
            <AppButton @click="$emit('close')" size="small" type="secondary" width="full">{{ $t('no-machine-selected-dialog.button-close') }}</AppButton>
        </div>
    </BaseDialog>
</template>

<script>
import BaseDialog from '@/components/Interface/BaseDialog.vue';
import AppButton from '@/components/Interface/AppButton.vue';
import router from '@/router';

export default {
    name: 'NoMachineSelectedDialog',

    components: {
        BaseDialog,
        AppButton,
    },

    props: {
        redirect: {
            type: String,
            default: 'HomeScreen',
        },
    },

    setup(props, { emit }) {
        /**
         * Method to close dialog and navigate to machine overview
         */
        const selectMachine = () => {
            router.push({ name: 'HomeScreen' });
            emit('close');
        };

        return {
            selectMachine,
        };
    },
};
</script>

<style lang="scss">
.NoMachineSelectedDialog {

    &_Actions .ui-AppButton {
        @apply px-3;
    }
}
</style>
