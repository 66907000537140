<template>
    <!-- eslint-disable max-len -->
    <transition name="drawer" appear>
        <div @click="allowClose ? $emit('close') : null" class="ui-BaseDrawer fixed inset-0 z-30 flex items-end">
            <div
                @touchstart="handleTouchStart"
                @touchmove="handleTouchMove"
                @touchend="handleTouchEnd"
                @click.stop
                ref="drawerElement"
                class="ui-BaseDrawer_Inner px-4 pb-10 rounded-t-3xl bg-day-surface dark:bg-night-02dp w-full overflow-y-auto"
                :class="{ 'pt-8': !allowClose, 'pt-4': allowClose }">

                <div v-if="allowClose" class="ui-BaseDrawer_Dragger h-1 rounded-full mx-auto mb-4 cursor-move pointer-events-none" />

                <slot />
            </div>
        </div>
    </transition>
</template>

<script>
/* eslint-disable max-len  */
import { reactive } from '@vue/composition-api';

export default {
    name: 'UIBaseDrawer',

    props: {
        allowClose: {
            type: Boolean,
            default: true,
        },
    },

    setup(props, { refs, emit }) {
        const touchData = reactive({
            active: false,
            currentY: null,
            initialY: null,
            yOffset: 0,
        });

        /**
         * Start touch event handler
         *
         * @param {Object} event
         * @returns {void}
         */
        const handleTouchStart = (event) => {
            if (props.allowClose) {
                touchData.initialY = event.touches[0].clientY - touchData.yOffset;

                // Reset transition from auto drag
                const { drawerElement } = refs;

                // If drawer content is overflowing, check if scroll position is at top
                if (drawerElement.scrollHeight > drawerElement.clientHeight && drawerElement.scrollTop > 0) {
                    return;
                }

                drawerElement.style.transition = 'none';

                touchData.active = true;
            }
        };

        /**
         * Touch move event handler
         *
         * @param {Object} event
         * @returns {void}
         */
        const handleTouchMove = (event) => {
            if (props.allowClose && touchData.active) {
                touchData.currentY = event.touches[0].clientY - touchData.initialY;

                const { drawerElement } = refs;

                // If drawer content is overflowing, check if scroll position is at top
                if (drawerElement.scrollHeight > drawerElement.clientHeight && drawerElement.scrollTop > 0) {
                    return;
                }

                // Should not be able to drag the drawer above it's own height
                if (drawerElement && touchData.currentY > 0) {
                    touchData.yOffset = touchData.currentY;

                    // Set transform translate on element
                    drawerElement.style.transform = `translateY(${touchData.currentY}px)`;
                }
            }
        };

        /**
         * Touch end event handler
         * @returns {void}
         */
        const handleTouchEnd = () => {
            touchData.initialY = touchData.currentY;
            touchData.active = false;

            const { drawerElement } = refs;
            const drawerHeight = drawerElement?.offsetHeight;

            // Check if drawer element is still in DOM
            if (drawerElement) {
                drawerElement.style.transition = 'transform 200ms ease';

                // Check if drag ends at top of bottom half of the drawer height
                // Close or fully open based on drag position with transition
                if (touchData.currentY <= drawerHeight / 2) {
                    drawerElement.style.transform = 'translateY(0px)';
                } else {
                    drawerElement.style.transform = `translateY(${drawerHeight}px)`;

                    // Await transition and emit close event
                    setTimeout(() => {
                        emit('close');
                    }, 200);
                }
            }

            // Reset the Y offset
            touchData.yOffset = 0;
        };

        return {
            handleTouchStart,
            handleTouchMove,
            handleTouchEnd,
        };
    },
};
</script>

<style lang="scss">
.ui-BaseDrawer {
    background: rgba(0, 0, 0, 0.3);

    &_Dragger {
        background: #E0E0E0;
        width: 3.5rem;
    }

    &_Inner {
        max-height: 100vh; /* Fallback for browsers that do not support Custom Properties */
        max-height: calc(var(--vh, 1vh) * 90);
    }
}

.mode-dark {

    .ui-BaseDrawer {
        background: rgba(0, 0, 0, 0.4);

        &_Dragger {
            background: rgba(255, 255, 255, 0.12);
        }
    }
}

.drawer-enter-active,
.drawer-leave-active {
    transition: all 350ms linear;
    background: rgba(0, 0, 0, 0.4) !important;

    .ui-BaseDrawer_Inner {
        transition: all 350ms linear !important;
    }
}

.drawer-enter,
.drawer-leave-to {
    background: rgba(0, 0, 0, 0.0) !important;

    .ui-BaseDrawer_Inner {
        transform: translateY(100%) !important;
    }
}
</style>
